<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link
            :to="child.id ? `/profile/child/${child.id}` : `/profile`"
            class="back-button"
            title="back"
          >
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 v-if="isEditing" class="mb-0 ion-text-center">Edit {{ child.initials }}</h2>
          <h2 v-else class="mb-0 ion-text-center">Add new child</h2>
        </ion-title>

        <ion-buttons slot="end">
          <div style="visibility: hidden"><ion-back-button default-href="/"></ion-back-button></div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <form @submit.prevent="saveChanges" class="horizontally-centered">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Child’s first name?</ion-label>

                <ion-input
                  required
                  name="initials"
                  :maxlength="20"
                  :value="child.initials"
                  v-model="newChildData.initials"
                ></ion-input>
              </ion-item> </ion-col
          ></ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Date of birth</ion-label>
                <ion-datetime
                  name="age"
                  placeholder="July 2018"
                  display-format="MMMM YYYY"
                  :value="child.age"
                  required
                  v-model="newChildData.age"
                ></ion-datetime>
              </ion-item> </ion-col
          ></ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Primary prognosis</ion-label>
                <ion-select
                  :value="child.prognosis"
                  name="prognosis"
                  required
                  placeholder="Choose one"
                  v-model="newChildData.prognosis"
                >
                  <ion-select-option v-for="pro in prognosis" :key="pro" :value="pro">{{
                    pro
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked"
                  >Does your child have an co-occuring conditions?
                </ion-label>

                <ion-select
                  :value="child.conditions"
                  name="conditions"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.conditions"
                >
                  <ion-select-option
                    v-for="condition in conditions"
                    :key="condition"
                    :value="condition"
                    >{{ condition }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">How does your child communicate?</ion-label>

                <ion-select
                  :value="child.communicate"
                  name="communicate"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.communicate"
                >
                  <ion-select-option
                    v-for="communication in communications"
                    :key="communication"
                    :value="communication"
                    >{{ communication }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-radio-group :value="child.pain" v-model="newChildData.pain" name="pain">
                <ion-list-header>
                  <ion-label mode="md" position="stacked"
                    >Does your child experience pain at night?</ion-label
                  >
                </ion-list-header>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="pain"
                        mode="md"
                        value="yes"
                      ></ion-radio>
                      <ion-label>Yes</ion-label>
                    </ion-item>
                  </ion-col>

                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="pain"
                        mode="md"
                        value="no"
                      ></ion-radio>
                      <ion-label>No</ion-label>
                    </ion-item>
                  </ion-col>

                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="pain"
                        mode="md"
                        value="unsure"
                      ></ion-radio>
                      <ion-label>Unsure</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-radio-group
                :value="child.onPrescriptionMedication"
                v-model="newChildData.onPrescriptionMedication"
                name="medication"
              >
                <ion-list-header>
                  <ion-label mode="md" position="stacked"
                    >Is your child currently taking any prescription only medication?</ion-label
                  >
                </ion-list-header>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="onPrescriptionMedication"
                        mode="md"
                        value="1"
                      ></ion-radio>
                      <ion-label>Yes</ion-label>
                    </ion-item>
                  </ion-col>

                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="onPrescriptionMedication"
                        mode="md"
                        value="0"
                      ></ion-radio>
                      <ion-label>No</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </ion-col>
          </ion-row>

          <ion-row v-if="newChildData.onPrescriptionMedication === '1'">
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Please, select all that apply</ion-label>

                <ion-select
                  color="primary"
                  :value="child.medications"
                  name="medications"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.medications"
                >
                  <ion-select-option
                    v-for="medication in medications"
                    :key="medication"
                    :value="medication"
                    >{{ medication }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <div class="ion-padding-top">
            <ion-text>
              <h3>
                The following questions are about your child’s sleep and their environment.
              </h3>
              <p>
                These are optional, but they would greatly help us going forward and would
                appreaciate your help.
              </p>
            </ion-text>
          </div>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked"
                  >Does your child have problems with any of the following
                </ion-label>
                <ion-note color="white">e.g. 4 or more nights a week</ion-note>

                <ion-select
                  :value="child.problems"
                  name="problems"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.problems"
                >
                  <ion-select-option v-for="problem in problems" :key="problem" :value="problem">{{
                    problem
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Which of these is the biggest problem? </ion-label>

                <ion-select
                  :value="child.biggestProblem"
                  name="biggestProblem"
                  required
                  placeholder="Choose one"
                  v-model="newChildData.biggestProblem"
                >
                  <ion-select-option v-for="problem in problems" :key="problem" :value="problem">{{
                    problem
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked"
                  >How do you think this affects your child during the day?</ion-label
                >

                <ion-select
                  :value="child.dayAffects"
                  name="dayAffects"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.dayAffects"
                >
                  <ion-select-option v-for="affect in dayAffects" :key="affect" :value="affect">{{
                    affect
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">When did this problem with sleep start?</ion-label>

                <ion-select
                  :value="child.problemStart"
                  name="problemStart"
                  required
                  placeholder="Select"
                  v-model="newChildData.problemStart"
                >
                  <ion-select-option v-for="start in problemStarts" :key="start" :value="start">{{
                    start
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked"
                  >To help your child’s sleep, what of these do you currently use?</ion-label
                >

                <ion-select
                  :value="child.sleepAides"
                  name="sleepAides"
                  required
                  multiple="true"
                  placeholder="Select all that apply"
                  v-model="newChildData.sleepAides"
                >
                  <ion-select-option v-for="aide in sleepAides" :key="aide" :value="aide">{{
                    aide
                  }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-radio-group :value="child.ownRoom" v-model="newChildData.ownRoom" name="ownRoom">
                <ion-list-header>
                  <ion-label mode="md" position="stacked"
                    >Does your child have their own room?</ion-label
                  >
                </ion-list-header>

                <ion-item lines="none">
                  <ion-radio color="primary" name="ownRoom" mode="md" value="1"></ion-radio>
                  <ion-label>Yes, child sleeps regularly in own room</ion-label>
                </ion-item>

                <ion-item lines="none">
                  <ion-radio color="primary" name="ownRoom" mode="md" value="0"></ion-radio>
                  <ion-label>No, child regularly shares a room with a family member</ion-label>
                </ion-item>
              </ion-radio-group>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-radio-group
                :value="child.firstTimeSeekingHelp"
                v-model="newChildData.firstTimeSeekingHelp"
                name="ownRoom"
              >
                <ion-list-header>
                  <ion-label mode="md" position="stacked"
                    >Is this the first time you have sought help for your child’s sleep
                    problem?</ion-label
                  >
                </ion-list-header>

                <ion-item lines="none">
                  <ion-radio
                    color="primary"
                    name="firstTimeSeekingHelp"
                    mode="md"
                    value="1"
                  ></ion-radio>
                  <ion-label>Yes</ion-label>
                </ion-item>

                <ion-item lines="none">
                  <ion-radio
                    color="primary"
                    name="firstTimeSeekingHelp"
                    mode="md"
                    value="0"
                  ></ion-radio>
                  <ion-label>No</ion-label>
                </ion-item>
              </ion-radio-group>
            </ion-col>
          </ion-row>

          <div id="appointment"></div>

          <ion-row v-if="hasLoaded">
            <ion-col>
              <ion-item lines="none">
                <ion-label position="stacked">Next appointment</ion-label>
                <ion-datetime
                  name="nextAppointment"
                  placeholder="10:23 23/August/2022"
                  display-format="HH:mm DD/MMMM/YYYY"
                  picker-format="HH':'mm DD/MMMM/YYYY"
                  minute-values="0,5,10,15,20,25,30,35,40,45,50,55"
                  :value="newChildData.nextAppointment"
                  required="false"
                  :min="today"
                  :max="maxAppointmentYear"
                  :pickerOptions="appointmentPickerOptions"
                ></ion-datetime>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-radio-group
                :value="child.shareData"
                v-model="newChildData.shareData"
                name="shareData"
              >
                <ion-list-header>
                  <ion-label mode="md" position="stacked"
                    >UCL would like to review your child's data for medical research purposes
                  </ion-label>
                </ion-list-header>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="shareData"
                        mode="md"
                        value="1"
                      ></ion-radio>
                      <ion-label>I agree</ion-label>
                    </ion-item>
                  </ion-col>

                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio
                        required
                        color="primary"
                        name="shareData"
                        mode="md"
                        value="0"
                      ></ion-radio>
                      <ion-label>I do not agree</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <div class="ion-padding-top">
                <ion-button expand="block" size="large" color="tertiary" type="submit">
                  <ion-spinner v-if="hasBeenSubmitted" name="crescent"></ion-spinner>
                  Save changes
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid v-if="isEditing">
          <ion-row>
            <ion-col class="ion-text-center">
              <ion-button
                expand="block"
                size="large"
                color="danger"
                @click.prevent="presentDeleteAlert"
              >
                DELETE THIS CHILD'S DATA
                <ion-ripple-effect></ion-ripple-effect> </ion-button
            ></ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import axios from '@/axios';
import { computed, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import {
  IonCol,
  IonItem,
  IonRow,
  IonPage,
  IonLabel,
  IonNote,
  IonInput,
  IonDatetime,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonSelect,
  IonTitle,
  IonRippleEffect,
  IonContent,
  IonSpinner,
  toastController,
  alertController
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'ChildEdit',
  components: {
    IonCol,
    IonItem,
    IonRow,
    IonPage,
    IonLabel,
    IonNote,
    IonInput,
    IonDatetime,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    IonSelect,
    IonTitle,
    IonRippleEffect,
    IonSpinner,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const childId = Number(route.params.id) || null;
    const isEditing = childId ? true : false;
    const today = new Date().toISOString();
    const max = new Date();
    max.setUTCFullYear(max.getFullYear() + 10);
    const maxAppointmentYear = max.toISOString();

    const child = store.getters['user/childById'](childId);
    const conditions = [
      'ADHD',
      'Autism Spectrum Disorder',
      'Brain Injury',
      'Cerebral Palsy',
      'Developmental co-ordination disorder',
      'Down’s Syndrome',
      'Dyslexia',
      'Epilepsy',
      'Intellectual or developmental condition (e.g. Rett Syndrome)',
      'Learning Difficulty',
      'Physical condition (e.g. hypertension, GI issues)',
      'Social Emotional & Mental Health (e.g. Anxiety, Pathological Demand Avoidance) [free text to specify]',
      'Sensory Processing Disorder',
      'Swan',
      'Other'
    ];

    const prognosis = [
      'ADHD',
      'Autism Spectrum Disorder',
      'Brain Injury',
      'Cerebral Palsy',
      'Developmental co-ordination disorder',
      'Down’s Syndrome',
      'Dyslexia',
      'Epilepsy',
      'Intellectual or developmental condition (e.g. Rett Syndrome)',
      'Learning Difficulty',
      'Physical condition (e.g. hypertension, GI issues)',
      'Social Emotional & Mental Health (e.g. Anxiety, Pathological Demand Avoidance) [free text to specify]',
      'Sensory Processing Disorder',
      'Swan',
      'Other'
    ];

    const communications = [
      'Language',
      'Behaviours',
      'Movements',
      'Facial Expressions',
      'Sounds',
      'Gestures',
      'Makaton',
      'Sign Language',
      'PECS',
      'Other'
    ];

    const medications = [
      'Analgesics (i.e. painkillers)',
      'Antiarrhythmics (for cardiac arrhythmias)',
      'Antibiotics',
      'Anticoagulants (blood thinners)',
      'Anticonvulsants (epileptic and non-epileptic seizures)',
      'Antidepressants ',
      'Antiemetics (vomiting and nausea)',
      'Antihypertensives (for hypertension)',
      'Antihyperglycemics (raised blood sugars)',
      'Bronchodilators (Asthma)',
      'Diuretics ',
      'Sedatives',
      'Statins',
      'Supplements',
      'Laxatives',
      'Proton Pump Inhibitors'
    ];

    const problems = [
      'Falling Asleep',
      'Staying Asleep',
      'Waking up too early',
      'Daytime Sleepiness',
      'Sleeps too little',
      'Sleeps too much',
      'Sleep Apnoea (diagnosed)',
      'Narcolepsy (diagnosed)',
      'Restless Legs Syndrome (diagnosed)',
      'Nightmares',
      'Night terrors'
    ];

    const dayAffects = [
      'Behaviour (hyperactivity)',
      'Focus, concentration',
      'Fatigue (visible, rubbing eyes, falling asleep)',
      'Other'
    ];

    const problemStarts = [
      'Less than 6 months',
      '6-12 months ago',
      '12-24 months',
      '2-5 years',
      '5-10 years',
      '10+ years'
    ];

    const sleepAides = [
      'Melatonin (prescribed)',
      'Melatonin (unprescribed)',
      'Melatonin gummies',
      'Weighted blanket',
      'Staying with the child until they go to sleep',
      'Bedtime routine',
      'Child co-sleeps in bed with parent/caregiver',
      'Other'
    ];

    onMounted(() => {
      if (isEditing) {
        Object.keys(child).forEach(key => {
          form.newChildData[key] = child[key];
        });
      }

      setTimeout(() => (form.hasLoaded = true), 0);
      setTimeout(() => {
        form.hasLoaded = true;
        if (document && route.hash.includes('appointment')) {
          const anchor = document.getElementById('appointment');

          if (anchor) {
            anchor.scrollIntoView();
          }
        }
      }, 100);
    });

    const form = reactive({
      newChildData: { nextAppointment: '', conditions: [], shareData: 0 } as any,
      hasChanges: false,
      hasBeenSubmitted: false,
      hasLoaded: false
    });

    const hasCondition = function(condition) {
      if (child.conditions && child.conditions.length) {
        return child.conditions.includes(condition);
      }

      return false;
    };

    const resetForm = () => {
      setTimeout(() => {
        form.hasChanges = false;
        form.hasBeenSubmitted = false;
      }, 300);
    };

    const saveChanges = async function saveFormChanges(e) {
      e.preventDefault();

      form.hasBeenSubmitted = true;
      // ionic sucks with checkboxes and radios....

      const formData = Object.assign({}, child, form.newChildData);

      if (isEditing) {
        const url = `wp-json/user/v1/child/${childId}`;
        await axios
          .put(url, formData)
          .then(submitSuccessful)
          .catch(submitFailed);
      } else {
        const url = `wp-json/user/v1/child`;
        await axios
          .post(url, formData)
          .then(submitSuccessful)
          .catch(submitFailed);
      }
    };

    async function submitSuccessful(req) {
      resetForm();

      const toast = await toastController.create({
        color: 'success',
        message: 'Your changes have been saved.',
        duration: 5000,
        buttons: [
          {
            text: 'x',
            role: 'cancel'
          }
        ]
      });

      await store.dispatch('user/getDataAPI');

      toast.present();

      if (req?.data?.id) {
        router.replace(`/profile/child/${req.data.id}`);
      } else if (childId) {
        router.push(`/profile/child/${childId}`);
      }
    }

    async function submitFailed(err) {
      const message = err?.response?.data?.message || 'Error saving your changes.';

      const toast = await toastController.create({
        color: 'danger',
        message: message,
        duration: 5000
      });

      form.hasBeenSubmitted = false;

      return toast.present();
    }

    const shouldDisableSubmitButton = computed(() => {
      const hasChangesOrSubmitted = !form.hasChanges || form.hasBeenSubmitted;

      if (isEditing) {
        return hasChangesOrSubmitted;
      }

      const keysInPlace: string[] = [];
      let allFilled = false;

      Object.keys(form.newChildData).forEach(key => {
        if (form.newChildData[key] !== '') {
          keysInPlace.push(key);
        }
      });

      if (keysInPlace.length >= 5) {
        allFilled = true;
      }

      return !(allFilled && form.hasChanges && !form.hasBeenSubmitted);
    });

    const appointmentPickerOptions = {
      cssClass: 'dias-datetime',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => console.log('Clicked Save!')
        },
        {
          text: 'Clear',
          handler: () => {
            form.newChildData.nextAppointment = null;
          }
        },
        {
          text: 'Save',
          handler: data => {
            let year: string = data.year.text;
            let month: string =
              data.month.value < 10
                ? '0' + data.month.value.toString()
                : data.month.value.toString();
            let day: string = data.day.text;
            let hour: string = data.hour.text;
            let minute: string = data.minute.text;
            const newDate = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00';
            form.newChildData.nextAppointment = newDate;
          }
        }
      ]
    };

    const presentDeleteAlert = async function() {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Remove Child',
        subHeader: child.initials,
        message: 'All their data will be <em>permanently</em> deleted.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            role: 'confirm'
          }
        ]
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();
      if (role !== 'cancel') {
        deleteChild();
      }
    };

    async function deleteSuccessful() {
      await toastController.create({
        color: 'success',
        message: 'The child has been removed successfully.',
        duration: 5000,
        buttons: [
          {
            text: 'x',
            role: 'cancel'
          }
        ]
      });

      await store.dispatch('user/getDataAPI');

      router.push('/profile');
    }

    const deleteChild = async function() {
      if (!isEditing) {
        return;
      }

      const url = `wp-json/user/v1/child/${childId}`;
      await axios
        .delete(url)
        .then(deleteSuccessful)
        .catch(submitFailed);
    };

    return {
      chevronBackOutline,
      presentDeleteAlert,
      hasCondition,
      appointmentPickerOptions,
      today,
      maxAppointmentYear,
      conditions,
      prognosis,
      communications,
      medications,
      problems,
      dayAffects,
      problemStarts,
      sleepAides,
      child,
      saveChanges,
      isEditing,
      shouldDisableSubmitButton,
      ...toRefs(form)
    };
  }
};
</script>
